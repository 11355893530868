import React, { useRef, useEffect, ReactNode } from "react";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import { MdClose } from "react-icons/md";

import "./Modal.css";
import useLockBodyScroll, { TOUCH_MOVE_CONTAINER_CLASS_NAME } from "lib/useLockBodyScroll";
import { ReactComponent as BackArrow } from "img/BackArrow.svg";
import Sheet from "react-modal-sheet";
import { useMedia } from "react-use";
import useThemeSwitcher from "lib/useThemeSwitcher";

export type Props = {
  label: string;
  isVisible: boolean;
  showHeaderDivider?: boolean;
  setIsVisible: (arg: boolean) => void;
  className?: string;
  zIndex?: number;
  onAfterOpen?: () => void;
  disableBodyScrollLock?: boolean;
  allowContentTouchMove?: boolean;
  children?: ReactNode;
  placement?: "center" | "right";
  noMinWidth?: boolean;
  isSecondModal?: boolean;
  customWidth?: boolean;
  isInputFocused?: boolean;
  description?: string;
  disAllowClose?: boolean;
};

// ideally use ModalWithPortal
export default function Modal(props: Props) {
  const {
    isVisible,
    showHeaderDivider,
    setIsVisible,
    className,
    zIndex,
    onAfterOpen,
    disableBodyScrollLock,
    allowContentTouchMove,
    isSecondModal,
    customWidth,
    disAllowClose,
  } = props;

  const modalRef = useRef(null);
  useLockBodyScroll(modalRef, isVisible, {
    disableLock: disableBodyScrollLock,
    allowTouchMove: allowContentTouchMove,
  });

  const { isDarkTheme } = useThemeSwitcher();

  useEffect(() => {
    function close(e) {
      if (e.keyCode === 27 && setIsVisible) {
        setIsVisible(false);
      }
    }
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [setIsVisible]);

  useEffect(() => {
    if (typeof onAfterOpen === "function") onAfterOpen();
  }, [onAfterOpen]);

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const isMobile = useMedia("(max-width: 1100px)");

  return (
    <>
      <AnimatePresence>
        {isVisible && !isMobile && (
          <motion.div
            className={cx("Modal", className, {
              "placement-center": props.placement === "center",
              "placement-right": props.placement === "right",
            })}
            style={{ zIndex }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={fadeVariants}
            transition={{ duration: 0.2 }}
          >
            <div
              className="fixed inset-0 bg-[rgba(9,_9,_12,_0.65)] h-[100vh]"
              style={{
                overflow: isVisible ? "hidden" : "visible",
                position: "fixed",
                backdropFilter: isVisible && "blur(5px)",
              }}
              onClick={() => {
                if (!disAllowClose) {
                  setIsVisible(false);
                }
              }}
            ></div>
            {/* we use a custom color for modals */}
            <div
              className={cx("Modal-content", {
                "Modal-content--custom-width": !!customWidth,
                "bg-[#0F1014]": isDarkTheme,
                "bg-background-1": !isDarkTheme,
                "bg-[#101014]": isDarkTheme,
              })}
            >
              <div
                className="Modal-top-line"
                onClick={() => {
                  setIsVisible(false);
                }}
              />
              <div className="Modal-title-bar mb-[1rem]">
                {isSecondModal && (
                  <div className="Modal-back-button">
                    <div className="h-[2rem]">
                      <BackArrow
                        className="fill-textColor"
                        onClick={() => {
                          setIsVisible(false);
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="Modal-title">{props.label}</div>
                {!disAllowClose && (
                  <div
                    className="Modal-close-button"
                    onClick={() => {
                      setIsVisible(false);
                    }}
                  >
                    <MdClose fontSize={15} className="Modal-close-icon" />
                  </div>
                )}
              </div>

              <div className="Modal-description">{props.description}</div>

              {showHeaderDivider && <div className="Modal-divider"></div>}

              <div
                className={cx("Modal-body", TOUCH_MOVE_CONTAINER_CLASS_NAME, {
                  "Modal-body--no-top-margin": showHeaderDivider,
                  "Modal-body--no-min-width": props.noMinWidth,
                })}
                ref={modalRef}
              >
                {props.children}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <Sheet
        isOpen={isVisible && isMobile}
        onClose={() => setIsVisible(false)}
        detent={props.isInputFocused ? "full-height" : "content-height"}
      >
        <Sheet.Container className={"bg-background-1"} style={{ backgroundColor: "" }}>
          <Sheet.Header>
            <div className="Modal-top-line" />
            <div className="Modal-title-bar">
              {isSecondModal && (
                <div className="Modal-back-button">
                  <div className="h-[2rem]">
                    <BackArrow
                      className="fill-textColor"
                      onClick={() => {
                        setIsVisible(false);
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="Modal-title">{props.label}</div>
            </div>
          </Sheet.Header>

          <Sheet.Content disableDrag={true}>
            <Sheet.Scroller draggableAt="both">
              <div>{props.children}</div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => {
            setIsVisible(false);
          }}
        />
      </Sheet>
    </>
  );
}

Modal.defaultProps = {
  placement: "center",
} satisfies Partial<Props>;
