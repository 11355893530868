import React, { useState, useEffect, useCallback } from "react";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import Button from "components/Button/Button";
import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useConnectWalletUi } from "lib/useConnectWalletUi";
import { BigNumber, ethers } from "ethers";
import VaultConfig from "abis/common/KitsuneVault.json";
import ControllerConfig from "abis/common/KitsuneController.json";

export interface ActionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  actionType: "Deposit" | "Withdraw" | "Request Withdrawal";
  onSubmit: (inputValues: { amount?: string }) => void;
  label: string;
}

const ActionModal: React.FC<ActionModalProps> = ({ open, setOpen, actionType, onSubmit, label }) => {
  const [amount, setAmount] = useState<string>("");
  const [balance, setBalance] = useState<string>("0");
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const { isActive, account, provider } = useWeb3React();
  const { connectWallet } = useConnectWalletUi();

  const vaultAddress = VaultConfig.address;
  const controllerAddress = ControllerConfig.address;

  const getAllPreviousRedeemValue = async () => {
    if (!provider || !account) return BigNumber.from(0);

    try {
      const controllerContract = new ethers.Contract(controllerAddress, ControllerConfig.abi, provider.getSigner());
      const currentEpoch = Number(await controllerContract._epoch());
      const allRedeems: BigNumber[] = [];
      // get localStorage value for the starting epoch
      const startingEpoch = parseInt(`${window.localStorage.getItem('startingEpoch') || 0}`);
      let shouldUpdateLocalStorage = true;
      for (let i = startingEpoch; i < currentEpoch + 1; i++) {
        const redeem: BigNumber = await controllerContract._redeemRequests(account, i);
        if(redeem.eq(0) && shouldUpdateLocalStorage) {
          window.localStorage.setItem('startingEpoch', i.toString());
        } else {
          allRedeems.push(redeem);
          shouldUpdateLocalStorage = false;
        }
      }
      // reduce allRedeems to get the total value
      const totalRedeem = allRedeems.reduce((acc, val) => acc.add(val), BigNumber.from(0));
      return totalRedeem;
    } catch (error) {
      return BigNumber.from(0);
    }
  };

  const fetchBalance = useCallback(async () => {
    if (!provider || !account) return;

    setIsFetching(true);
    try {
      const vaultContract = new ethers.Contract(vaultAddress, VaultConfig.abi, provider.getSigner());
      // const controllerContract = new ethers.Contract(controllerAddress, ControllerConfig.abi, provider.getSigner());

      if (actionType === "Deposit") {
        // Fetch user's USDC balance
        const usdcContract = new ethers.Contract(await vaultContract.asset(), VaultConfig.abi, provider.getSigner());
        const usdcBalance = await usdcContract.balanceOf(account);
        setBalance(ethers.utils.formatUnits(usdcBalance, 6));
      } 
      
      else if (actionType === "Request Withdrawal") {
        // 1) total KIT balance
        const kitBalanceBN = await vaultContract.balanceOf(account);
        const alreadyRequestedBN = await getAllPreviousRedeemValue();
        
        const maxNewRequestBN = kitBalanceBN.sub(alreadyRequestedBN);
        const maxNewFinal = maxNewRequestBN.gt(0) ? maxNewRequestBN : BigNumber.from(0);
        setBalance(ethers.utils.formatUnits(maxNewFinal, 18));
      } 
      
      else if (actionType === "Withdraw") {
        const alreadyRequestedBN = await getAllPreviousRedeemValue();
        setBalance(ethers.utils.formatUnits(alreadyRequestedBN, 18));
      }

    } catch (error) {
      setBalance("0");
    } finally {
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, account, actionType, vaultAddress]);

  useEffect(() => {
    if (isActive) {
      fetchBalance();
    }
  }, [isActive, actionType, fetchBalance]);

  const handleClose = () => {
    setOpen(false);
    setAmount("");
  };

  const handleSubmit = () => {
    if (parseFloat(amount) > parseFloat(balance) || parseFloat(amount) <= 0) return;
    const inputValues = actionType === "Withdraw" ? {} : { amount };
    onSubmit(inputValues);
    handleClose();
  };

  const handleMax = () => {
    setAmount(balance);
  };

  const isSubmitDisabled = actionType === "Withdraw" ? !balance || parseFloat(balance) <= 0 :
  !amount || parseFloat(amount) <= 0 || parseFloat(amount) > parseFloat(balance);

  return (
    <ModalWithPortal className="tailwind" isVisible={open} setIsVisible={handleClose} label={label}>
      {isActive ? (
        <div className="flex flex-col w-full">
          <div className="flex flex-col gap-[1rem] w-full">
            <div className="flex justify-between items-center">
              <span className="text-[1.4rem]">
                {actionType === "Deposit" ? "USDC Balance:" : "Available KIT:"}
              </span>
              <span className="text-[1.4rem]">
                {isFetching ? "Loading..." : `${Number(balance).toLocaleString()} ${actionType === "Deposit" ? "USDC" : "KIT"}`}
              </span>
            </div>
            {actionType !== "Withdraw" && (
              <>
                <div className="text-[1.4rem]">{actionType} Amount</div>
                <div className="flex items-center gap-[0.5rem]">
                  <input
                    type="number"
                    placeholder="Enter amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full px-[1rem] py-[0.8rem] border border-border rounded-md"
                  />
                  <button
                    onClick={handleMax}
                    className="px-[1rem] py-[0.8rem] bg-main rounded-md"
                  >
                    Max
                  </button>
                </div>
              </>
            )}
          </div>
          <Button
            variant="primary-action"
            onClick={handleSubmit}
            className={`w-full mt-[1.5rem] rounded-md ${isSubmitDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={isSubmitDisabled}
          >
            <Trans>Submit</Trans>
          </Button>
        </div>
      ) : (
        <Button
          variant="primary-action"
          className="w-full"
          onClick={() => {
            connectWallet();
            handleClose();
          }}
        >
          <Trans>Connect Wallet</Trans>
        </Button>
      )}
    </ModalWithPortal>
  );
};

export default ActionModal;
