import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import TradeHistory from "components/Kitsune/TradeHistory";
import foxLogoBlack from "img/Fox_Logo_Black.svg";
import ActionModal from "components/Kitsune/ActionModal";

import ControllerConfig from "abis/common/KitsuneController.json";
import VaultConfig from "abis/common/KitsuneVault.json";
import TokenConfig from "abis/common/Token.json";

const Kitsune = () => {
  const [openModal, setOpenModal] = useState(false);
  const [actionType, setActionType] = useState<"Deposit" | "Withdraw" | "Request Withdrawal">("Deposit");
  const [userBalance, setUserBalance] = useState("0");
  const [userUsdValue, setUserUsdValue] = useState("0");
  const [tokenValue, setTokenValue] = useState("0");
  const [currentSupplyLimit, setCurrentSupplyLimit] = useState("[VARIABLE]");
  const [lastEpochEnd, setLastEpochEnd] = useState("HH:MM on DD/MM/YY");
  const [depositStatus, setDepositStatus] = useState("[Available/Unavailable]");
  const [overallSupply, setOverallSupply] = useState("0");
  const [overallUsdValue, setOverallUsdValue] = useState("0");
  const [totalDeposited, setTotalDeposited] = useState("0");

  const srcList = ["/kitsune/K1.png", "/kitsune/K2.png", "/kitsune/K3.png"];
  const initialRandomFox = srcList[Math.floor(Math.random() * srcList.length)];
  const [randomFox] = useState(initialRandomFox);

  const { provider, account } = useWeb3React();
  const controllerAddress = ControllerConfig.address;
  const vaultAddress = VaultConfig.address;

  const readOnlyProvider = new ethers.providers.FallbackProvider([
    new ethers.providers.JsonRpcProvider("https://arbitrum-one-rpc.publicnode.com"),
    new ethers.providers.JsonRpcProvider("https://arbitrum.drpc.org"),
  ]);
  const [vaultContract, setVaultContract] = useState<ethers.Contract | null>(
    new ethers.Contract(vaultAddress, VaultConfig.abi, readOnlyProvider)
  );
  const [controllerContract, setControllerContract] = useState<ethers.Contract | null>(
    new ethers.Contract(controllerAddress, ControllerConfig.abi, readOnlyProvider)
  );

  type TradeStatisticsT = {
    volume: number;
    foxifyFees: number;
    pnl: number;
    bestTrade: number;
    worstTrade: number;
  };
  type DashboardStatisticsT = {
    daily: TradeStatisticsT;
    weekly: TradeStatisticsT;
    allTime: TradeStatisticsT;
  };
  type RecentClosedTradeT = {
    id: number;
    name: string;
    symbol: string;
    direction: "LONG" | "SHORT";
    size: number;
    entryPrice: number;
    exitPrice: number;
    pnl: number;
    closeTime: Date;
  };

  const [statistics, setStatistics] = useState<DashboardStatisticsT>({
    daily: { volume: 0, foxifyFees: 0, pnl: 0, bestTrade: 0, worstTrade: 0 },
    weekly: { volume: 0, foxifyFees: 0, pnl: 0, bestTrade: 0, worstTrade: 0 },
    allTime: { volume: 0, foxifyFees: 0, pnl: 0, bestTrade: 0, worstTrade: 0 },
  });

  const [currentStats, setCurrentStats] = useState<TradeStatisticsT>({
    volume: 0,
    foxifyFees: 0,
    pnl: 0,
    bestTrade: 0,
    worstTrade: 0,
  });

  const isLocalStorageAvailable = (): boolean => {
    try {
      if (typeof window === "undefined") return false; // No window in SSR or certain environments
      if (!window.localStorage) return false; // localStorage might not be supported
      // Test writing to localStorage (some browsers in private mode may throw)
      const testKey = "__testLocalStorage__";
      window.localStorage.setItem(testKey, "test");
      window.localStorage.removeItem(testKey);
      return true;
    } catch {
      return false;
    }
  };

  useEffect(() => {
    // 2) On mount, create read-only contracts for global data
    const publicVaultContract = new ethers.Contract(VaultConfig.address, VaultConfig.abi, readOnlyProvider);
    const publicControllerContract = new ethers.Contract(
      ControllerConfig.address,
      ControllerConfig.abi,
      readOnlyProvider
    );

    setVaultContract(publicVaultContract);
    setControllerContract(publicControllerContract);

    // Fetch global data from the read-only provider
    fetchData(publicVaultContract, publicControllerContract, null);
    if (!provider) {
      setUserBalance("0");
      setUserUsdValue("0");
      setTokenValue("0");
    }
    fetchStatistics();
    fetchTradeHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (provider && account) {
      const signer = provider.getSigner();
      const userVault = new ethers.Contract(VaultConfig.address, VaultConfig.abi, signer);
      const userController = new ethers.Contract(ControllerConfig.address, ControllerConfig.abi, signer);

      setVaultContract(userVault);
      setControllerContract(userController);

      // Now fetch user data with signer if account is present
      fetchData(userVault, userController, account);
      if (!provider) {
        setUserBalance("0");
        setUserUsdValue("0");
        setTokenValue("0");
      }
      fetchStatistics();
      fetchTradeHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, account]);

  const [tradeHistoryData, setTradeHistoryData] = useState<RecentClosedTradeT[]>([]);

  const fetchStatistics = async () => {
    if (isLocalStorageAvailable()) {
      const localData = localStorage.getItem("statisticsData");
      if (localData) {
        try {
          // Parse and set immediately
          const parsedData = JSON.parse(localData) as DashboardStatisticsT;
          setStatistics(parsedData);
          setCurrentStats(parsedData.weekly);
        } catch (error) {
          // console.error("Could not parse local statisticsData", error);
        }
      }
    }
    try {
      const response = await fetch("https://kitsune.foxify.trade/api/statistics/dashboard", {
        mode: "cors",
      });
      if (!response.ok) throw new Error("Failed to fetch statistics data");
      const data = (await response.json()) as DashboardStatisticsT;
      setStatistics(data);
      setCurrentStats(data.weekly);

      if (isLocalStorageAvailable()) {
        localStorage.setItem("statisticsData", JSON.stringify(data));
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchTradeHistory = async () => {
    if (isLocalStorageAvailable()) {
      const localHistory = localStorage.getItem("tradeHistoryData");
      if (localHistory) {
        try {
          const parsedData = JSON.parse(localHistory) as RecentClosedTradeT[];
          setTradeHistoryData(parsedData);
        } catch (error) {
          // console.error("Could not parse local tradeHistoryData", error);
        }
      }
    }

    try {
      const response = await fetch("https://kitsune.foxify.trade/api/trades/recentclosed", {
        mode: "cors",
      });
      if (!response.ok) throw new Error("Failed to fetch history data");
      const data = (await response.json()) as RecentClosedTradeT[];
      const processedData = data.map((trade, index) => ({ ...trade, id: index + 1 }));
      setTradeHistoryData(processedData);

      if (isLocalStorageAvailable()) {
        localStorage.setItem("tradeHistoryData", JSON.stringify(processedData));
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchData = async (
    vaultInstance: ethers.Contract | null,
    controllerInstance: ethers.Contract | null,
    userAddress: string | null
  ) => {
    if (!vaultInstance || !controllerInstance) {
      return;
    }

    try {
      const totalAssets = await vaultInstance.totalAssets(); // 6 decimals
      const totalSupply = await vaultInstance.totalSupply(); // 18 decimals

      if (totalSupply.gt(0)) {
        const pricePerKit =
          Number(ethers.utils.formatUnits(totalAssets, 6)) / Number(ethers.utils.formatUnits(totalSupply, 18));
        setTokenValue(pricePerKit.toFixed(4));

        const overallValue = Number(ethers.utils.formatUnits(totalSupply, 18)) * pricePerKit;
        setOverallUsdValue(overallValue.toFixed(2));
      } else {
        setTokenValue("0");
        setOverallUsdValue("0");
      }

      const depositLimit = await controllerInstance._depositLimit();
      const totalDeposits = (await controllerInstance._totalDeposits()).currentEpoch;
      setCurrentSupplyLimit(ethers.utils.formatUnits(depositLimit.sub(totalDeposits), 6));

      const hardStart = new Date("Jan 15, 2025");
      const epochEndBlock = await controllerInstance._previousEpochStartedAt();
      // set the minimum of the last epoch to be the hardStart, get the max between the hardStart and the epochEndBlock
      const lastEpochStart = new Date(Math.max(hardStart.getTime(), epochEndBlock.toNumber() * 1000));
      setLastEpochEnd(lastEpochStart.toLocaleString());

      setDepositStatus(depositLimit.gt(totalDeposits) ? "Available" : "Unavailable");

      setOverallSupply(ethers.utils.formatUnits(totalSupply, 18));
      setTotalDeposited(ethers.utils.formatUnits(totalDeposits, 6));

      if (userAddress) {
        const balance = await vaultInstance.balanceOf(account);
        setUserBalance(ethers.utils.formatUnits(balance, 18));

        const usdValue = await vaultInstance.previewRedeem(balance);
        setUserUsdValue(ethers.utils.formatUnits(usdValue, 6));
      }
    } catch (error) {}
  };

  const handleSubmit = async (inputValues: { amount?: string | undefined }) => {
    if (!provider || !account || !controllerContract || !vaultContract) {
      return;
    }

    try {
      if (actionType === "Deposit") {
        const amount = inputValues.amount ? ethers.utils.parseUnits(inputValues.amount, 6) : ethers.BigNumber.from(0);
        const usdcAddress = await vaultContract.asset();
        const tokenContract = new ethers.Contract(usdcAddress, TokenConfig.abi, provider.getSigner());
        const allowance = await tokenContract.allowance(account, controllerAddress);

        if (allowance.lt(amount)) {
          const approveTx = await tokenContract.approve(controllerAddress, ethers.constants.MaxUint256);
          await approveTx.wait();
        }

        const depositTx = await controllerContract.deposit(amount, 0); // minShares = 0
        await depositTx.wait();
      }

      if (actionType === "Request Withdrawal") {
        const amount = inputValues.amount ? ethers.utils.parseUnits(inputValues.amount, 18) : ethers.BigNumber.from(0);
        const requestTx = await controllerContract.requestRedeem(amount);
        await requestTx.wait();
      }

      if (actionType === "Withdraw") {
        const withdrawTx = await controllerContract.redeem(account);
        await withdrawTx.wait();
      }

      await fetchData(vaultContract, controllerContract, account);
    } catch (error) {}
  };

  const handleOpenModal = (type: "Deposit" | "Withdraw" | "Request Withdrawal") => {
    setActionType(type);
    setOpenModal(true);
  };

  return (
    <div className="default-container tailwind w-full pt-[5rem] flex flex-col pb-[10rem] gap-[2rem]">
      <div className="flex-1 px-8 py-[4rem] flex flex-col gap-[2rem]">
        {/* Top Section */}
        <div className="flex flex-col md:flex-row justify-between gap-[2rem] w-full">
          {/* User Summary */}
          <div className="flex flex-col w-full bg-background-5-v2-only rounded-md py-[1.6rem] px-[2rem] border border-border">
            <div className="flex items-center gap-[0.8rem] mb-[1.6rem]">
              <span className="text-[1.6rem] font-semibold">User Summary</span>
            </div>
            <div className="flex flex-col sm:flex-row gap-[2rem] justify-evenly w-full">
              <div className="flex flex-col gap-[0.5rem] items-center text-center md:items-start md:text-left">
                <span className="text-muted text-[1.2rem] font-medium">Kitsune Balance</span>
                <span className="text-[2.4rem] font-semibold">{Number(userBalance).toLocaleString("en-US")} KIT</span>
              </div>
              <div className="flex flex-col gap-[0.5rem] items-center text-center md:items-start md:text-left">
                <span className="text-muted text-[1.2rem] font-medium">USD Value</span>
                <span className="text-[2.4rem] font-semibold">${Number(userUsdValue).toLocaleString("en-US")}</span>
              </div>
              <div className="flex flex-col gap-[0.5rem] items-center text-center md:items-start md:text-left">
                <span className="text-muted text-[1.2rem] font-medium">Token Value</span>
                <span className="text-[2.4rem] font-semibold">${Number(tokenValue).toLocaleString("en-US")}/KIT</span>
              </div>
            </div>
          </div>

          {/* Status Section */}
          <div className="flex flex-col gap-[1rem] w-full md:max-w-[30rem] bg-background-5-v2-only rounded-md p-[1.6rem] border border-border">
            <div className="flex items-center gap-[0.8rem]">
              <span className="text-[1.6rem] font-semibold">Status</span>
              <div className="h-[3rem] w-[3rem] rounded-[50%] bg-main flex items-center justify-center">
                <img className="w-[2.5rem]" src={foxLogoBlack} alt="FOX" />
              </div>
            </div>
            <div className="flex flex-col gap-[0.8rem] text-[1.4rem]">
              <div className="flex justify-between">
                <span className="text-muted">Current Supply Limit:</span>
                <span>{currentSupplyLimit}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-muted">Last Epoch End:</span>
                <span>{lastEpochEnd}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-muted">Deposit Status:</span>
                <span>{depositStatus}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Middle Section */}
        <div className="flex flex-col md:flex-row w-full gap-[2rem]">
          <div className="flex flex-col w-full">
            <div className="flex justify-evenly space-x-6 pb-6 w-full md:w-1/2">
              <button
                onClick={() => handleOpenModal("Deposit")}
                className="w-[15rem] text-main bg-button-primary px-[1.7rem] py-[1rem] Create-funded-btn rounded-[1.5rem] h-[4.5rem] text-[1.4rem] font-medium"
              >
                Deposit
              </button>
              <button
                onClick={() => handleOpenModal("Withdraw")}
                className="w-[15rem] text-main bg-button-primary px-[1.7rem] py-[1rem] Create-funded-btn rounded-[1.5rem] h-[4.5rem] text-[1.4rem] font-medium"
              >
                Withdraw
              </button>
              <button
                onClick={() => handleOpenModal("Request Withdrawal")}
                className="w-[15rem] text-main bg-button-primary px-[1.7rem] py-[1rem] Create-funded-btn rounded-[1.5rem] h-[4.5rem] text-[1.4rem] font-medium leading-6"
              >
                Request Withdrawal
              </button>
            </div>

            <div className="flex flex-col md:flex-row gap-[2rem]">
              <div className="flex flex-col bg-background-5-v2-only rounded-md py-[2rem] px-[2rem] border border-border w-full md:w-1/2">
                <div className="flex items-center gap-[1rem] mb-[2rem]">
                  <span className="text-[1.8rem] font-semibold">Kitsune Summary</span>
                </div>
                <div className="flex flex-col sm:grid grid-cols-2 gap-[2rem] p-8">
                  <div className="flex flex-col items-center text-center md:items-start md:text-left">
                    <span className="text-muted text-[1.2rem] font-medium">Overall Supply</span>
                    <span className="text-[2.4rem] font-semibold">
                      {Number(overallSupply).toLocaleString("en-US")} KIT
                    </span>
                  </div>
                  <div className="flex flex-col items-center text-center md:items-start md:text-left">
                    <span className="text-muted text-[1.2rem] font-medium">USD Value</span>
                    <span className="text-[2.4rem] font-semibold">
                      ${Number(overallUsdValue).toLocaleString("en-US")}
                    </span>
                  </div>
                  <div className="flex flex-col items-center text-center md:items-start md:text-left">
                    <span className="text-muted text-[1.2rem] font-medium">Total Deposited</span>
                    <span className="text-[2.4rem] font-semibold">
                      ${Number(totalDeposited).toLocaleString("en-US")}
                    </span>
                  </div>
                  <div className="flex flex-col items-center text-center md:items-start md:text-left">
                    <span className="text-muted text-[1.2rem] font-medium flex items-center gap-[1rem]">
                      Kitsune P&L
                      <select
                        className="bg-button-primary text-[1.2rem] Create-funded-btn font-medium py-[0.4rem] px-[1rem] rounded-md"
                        onChange={(e) => {
                          const selectedDuration = e.target.value;
                          setCurrentStats(statistics[selectedDuration]);
                        }}
                      >
                        <option value="daily">Daily</option>
                        <option value="weekly" selected>
                          Weekly
                        </option>
                        <option value="allTime">All Time</option>
                      </select>
                    </span>
                    <span className="text-[2.4rem] font-semibold mt-[0.5rem]">
                      ${Number(currentStats.pnl).toLocaleString("en-US")}
                    </span>
                  </div>
                  <div className="flex flex-col items-center text-center md:items-start md:text-left">
                    <span className="text-muted text-[1.2rem] font-medium">24H Trading Volume</span>
                    <span className="text-[2.4rem] font-semibold">
                      ${Number(currentStats.volume).toLocaleString("en-US")}
                    </span>
                  </div>
                  <div className="flex flex-col items-center text-center md:items-start md:text-left">
                    <span className="text-muted text-[1.2rem] font-medium">Best Trade</span>
                    <span className="text-[2.4rem] font-semibold">
                      ${Number(currentStats.bestTrade).toLocaleString("en-US")}
                    </span>
                  </div>
                </div>
              </div>

              {/* Kitsune Image */}
              <div className="flex flex-col bg-background-5-v2-only rounded-md p-[1.6rem] border border-border w-full md:w-1/2">
                <img className="w-full aspect-square object-cover rounded-md" src={randomFox} alt="Description" />
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <TradeHistory tradeHistoryData={tradeHistoryData} />
      </div>

      <ActionModal
        open={openModal}
        setOpen={setOpenModal}
        actionType={actionType}
        onSubmit={handleSubmit}
        label={`${actionType}`}
      />
    </div>
  );
};

export default Kitsune;
