import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";

type RecentClosedTradeT = {
  id: number;
  name: string;
  symbol: string;
  direction: "LONG" | "SHORT";
  size: number;
  entryPrice: number;
  exitPrice: number;
  pnl: number;
  closeTime: Date;
};

type TradeHistoryProps = {
  tradeHistoryData: RecentClosedTradeT[];
};

export default function TradeHistory({ tradeHistoryData }: TradeHistoryProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(true);

  const mobileRef = useRef<HTMLDivElement | null>(null);
  const desktopRef = useRef<HTMLDivElement | null>(null);

  const itemsPerPage = isMobileView ? 5 : 10;
  const totalPages = Math.ceil(tradeHistoryData.length / itemsPerPage);

  // Paginate data
  const paginatedData = tradeHistoryData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page navigation
  const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  // Use IntersectionObserver to detect which component (mobile or desktop) is visible
  useEffect(() => {
    const mobileElement = mobileRef.current;
    const desktopElement = desktopRef.current;

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (mobileElement && entry.target === mobileElement && entry.isIntersecting) {
          setIsMobileView(true);
          setCurrentPage(1); // Reset to page 1 when layout changes
        } else if (desktopElement && entry.target === desktopElement && entry.isIntersecting) {
          setIsMobileView(false);
          setCurrentPage(1); // Reset to page 1 when layout changes
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, { threshold: 0.5 });
    if (mobileElement) observer.observe(mobileElement);
    if (desktopElement) observer.observe(desktopElement);

    return () => {
      if (mobileElement) observer.unobserve(mobileElement);
      if (desktopElement) observer.unobserve(desktopElement);
    };
  }, []);

  const decimalFormat = (symbol, value) => {
    const moreDecimalPairs = ["ARB-USDC", "DOGE-USDC"]
    if (moreDecimalPairs.includes(symbol)) {
      return value.toLocaleString("en-US", { minimumFractionDigits: 4, maximumFractionDigits: 4 })
    } else {
      return value.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
  }

  return (
    <div className="flex flex-col bg-background-5-v2-only rounded-md py-[1.6rem] px-[2rem] border border-border w-full">
      {/* Table Title */}
      <div className="flex items-center justify-between mb-[1.6rem]">
        <span className="text-[1.6rem] font-semibold">Trade History</span>
        <div className="flex gap-[0.8rem]">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className={cx(
              "px-3 py-1 rounded-md",
              currentPage === 1 ? "bg-gray-500 text-white cursor-not-allowed" : "bg-main text-black"
            )}
          >
            Prev
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={cx(
              "px-3 py-1 rounded-md",
              currentPage === totalPages ? "bg-gray-500 text-white cursor-not-allowed" : "bg-main text-black"
            )}
          >
            Next
          </button>
        </div>
      </div>

      {/* Table for larger screens */}
      <div
        ref={desktopRef}
        className="hidden md:block"
      >
        <table className="w-full text-left border-collapse">
          <thead>
            <tr className="text-muted border-b border-border">
              <th className="py-[0.8rem] px-[0.5rem]">#</th>
              <th className="py-[0.8rem] px-[0.5rem]">Pair</th>
              <th className="py-[0.8rem] px-[0.5rem]">Direction</th>
              <th className="py-[0.8rem] px-[0.5rem]">Size</th>
              <th className="py-[0.8rem] px-[0.5rem]">Entry Price</th>
              <th className="py-[0.8rem] px-[0.5rem]">Exit Price</th>
              <th className="py-[0.8rem] px-[0.5rem]">P&L</th>
              <th className="py-[0.8rem] px-[0.5rem]">Close Time</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((trade, index) => (
              <tr
                key={trade.id}
                className={cx(
                  "border-b border-border",
                  index % 2 === 0 ? "bg-background-6" : "bg-background-5-v2-only"
                )}
              >
                <td className="py-[0.8rem] px-[0.5rem]">{trade.id}</td>
                <td className="py-[0.8rem] px-[0.5rem]">{trade.symbol}</td>
                <td className="py-[0.8rem] px-[0.5rem]">{trade.direction}</td>
                <td className="py-[0.8rem] px-[0.5rem]">{trade.size.toLocaleString('en-US')}</td>
                <td className="py-[0.8rem] px-[0.5rem]">${decimalFormat(trade.symbol, trade.entryPrice)}</td>
                <td className="py-[0.8rem] px-[0.5rem]">${decimalFormat(trade.symbol, trade.exitPrice)}</td>
                <td
                  className={cx(
                    "py-[0.8rem] px-[0.5rem]",
                    trade.pnl >= 0 ? "text-green-500" : "text-red-500"
                  )}
                >
                  ${trade.pnl.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </td>
                <td className="py-[0.8rem] px-[0.5rem]">
                  {new Date(trade.closeTime).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Card layout for smaller screens */}
      <div
        ref={mobileRef}
        className="block md:hidden space-y-[1.6rem]"
      >
        {paginatedData.map((trade, index) => (
          <div
            key={trade.id}
            className={cx(
              "flex flex-col gap-[0.8rem] p-[1.2rem] rounded-md border",
              index % 2 === 0 ? "bg-background-6" : "bg-background-5-v2-only"
            )}
          >
            <div className="flex justify-start">
              <span className="text-muted font-bold underline">#{trade.id}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-muted font-medium">Pair</span>
              <span className="font-semibold">{trade.symbol}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-muted font-medium">Direction</span>
              <span className="font-semibold">{trade.direction}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-muted font-medium">Size</span>
              <span className="font-semibold">{trade.size.toLocaleString('en-US')}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-muted font-medium">Entry Price</span>
              <span className="font-semibold">${decimalFormat(trade.symbol, trade.entryPrice)}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-muted font-medium">Exit Price</span>
              <span className="font-semibold">${decimalFormat(trade.symbol, trade.exitPrice)}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-muted font-medium">P&L</span>
              <span
                className={cx(
                  "font-semibold",
                  trade.pnl >= 0 ? "text-green-500" : "text-red-500"
                )}
              >
                ${trade.pnl.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-muted font-medium">Close Time</span>
              <span className="font-semibold">
                {new Date(trade.closeTime).toLocaleString()}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-[1.6rem]">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={cx(
            "px-4 py-2 rounded-md mx-2",
            currentPage === 1 ? "bg-gray-500 text-white cursor-not-allowed" : "bg-main text-black"
          )}
        >
          Prev
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={cx(
            "px-4 py-2 rounded-md mx-2",
            currentPage === totalPages ? "bg-gray-500 text-white cursor-not-allowed" : "bg-main text-black"
          )}
        >
          Next
        </button>
      </div>
    </div>
  );
}
