import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { SWRConfig } from "swr";
import { ethers } from "ethers";
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import useScrollToTop from "lib/useScrollToTop";
import { DynamicBlurredEffect } from "components/BlurredEffect";
import { Switch, Route, HashRouter as Router, Redirect, useLocation, useHistory } from "react-router-dom";
import { DEFAULT_SLIPPAGE_AMOUNT, BASIS_POINTS_DIVISOR, isMobileDevice, REFERRAL_CODE_QUERY_PARAM } from "lib/legacy";
import Ecosystem from "pages/Ecosystem/Ecosystem";
import { Exchange } from "pages/Exchange/Exchange";
import Actions from "pages/Actions/Actions";
import OrdersOverview from "pages/OrdersOverview/OrdersOverview";
import PositionsOverview from "pages/PositionsOverview/PositionsOverview";
import Referrals from "pages/Referrals/Referrals";
// import NftWallet from "pages/NftWallet/NftWallet";
import { cssTransition, ToastContainer, ToastPosition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "components/Modal/Modal";

import Checkbox from "components/Checkbox/Checkbox";

import "styles/Shared.css"; // Should be before tailwind styles
import "styles/Tailwind.css";
import "styles/Font.css";
import "./App.scss";
import "styles/Input.css";
import "styles/MenuItem.css";

import metamaskImg from "img/metamask.png";
import coinbaseImg from "img/coinbaseWallet.png";
import walletConnectImg from "img/walletconnect-circle-blue.svg";
import useEventToast from "components/EventToast/useEventToast";
import EventToastContainer from "components/EventToast/EventToastContainer";
import SEO from "components/Common/SEO";
import useRouteQuery from "lib/useRouteQuery";
import { encodeReferralCode } from "domain/referrals";
import { cancelMultipleOrders } from "domain/legacy";
import { getContractAddress } from "config/contracts";
import Vault from "abis/Vault.json";
import PositionRouter from "abis/PositionRouter.json";
import PageNotFound from "pages/PageNotFound/PageNotFound";

import { useMedia } from "react-use";

import Jobs from "pages/Jobs/Jobs";
import Buy from "pages/Buy/Buy";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { defaultLocale, dynamicActivate } from "lib/i18n";
import { AppHeader } from "components/Header/AppHeader";
import { NETWORK_METADATA } from "config/chains";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import {
  DISABLE_ORDER_VALIDATION_KEY,
  IS_PNL_IN_LEVERAGE_KEY,
  LANGUAGE_LOCALSTORAGE_KEY,
  REFERRAL_CODE_KEY,
  SHOULD_SHOW_POSITION_LINES_KEY,
  SHOW_PNL_AFTER_FEES_KEY,
  SLIPPAGE_BPS_KEY,
  NOTIFICATION_LOCALSTORAGE_POSITION_KEY,
  SETTINGS_LOCALSTORAGE_TRADING_LAYOUT_KEY,
} from "config/localStorage";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Button from "components/Button/Button";
import { AiOutlineArrowRight } from "react-icons/ai";
import SpinningLoader from "components/Common/SpinningLoader";
// import { HiChartBar, HiChartSquareBar, HiGift, HiUserGroup } from "react-icons/hi";
import { useLocalStorageReactive } from "lib/localStorage";
import { usePendingTxns } from "domain/transactions/usePendingTxns";
import { useIsDevelopment } from "lib/useIsDevelopment";
import {
  ConnectionType,
  connectors,
  getConnection,
  useTryActivateConnector,
  useTryDeactivateConnector,
} from "lib/wallets/connections";
import { walletsSupport } from "lib/wallets/walletsSupport";
import { useConnectWalletUi } from "lib/useConnectWalletUi";
import Portal from "components/Common/Portal";
import BridgeModal from "components/Bridge/BridgeModal";
import MarketInformation from "components/Exchange/MarketInformation";
import Portfolio from "pages/Portfolio/Portfolio";
import { hasUserAgreedTermsAtom } from "./state";
import { useAtom } from "jotai";
import Funded from "pages/Funded/Funded";
import { useFundedTrader } from "../hooks/useFundedTrader";
import Stake from "pages/Stake/Stake";
import Kitsune from "pages/Kitsune/Kitsune";

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  appendPosition: false,
  collapse: true,
  collapseDuration: 200,
  duration: 200,
});

function getWsProvider(active, chainId) {
  if (!active) {
    return;
  }

  const rpcUrl = NETWORK_METADATA[chainId].rpcUrls.ws[0];
  if (!rpcUrl) {
    return;
  }

  return new ethers.providers.WebSocketProvider(rpcUrl);
}

function FullApp() {
  const exchangeRef = useRef<any>();
  const { connector, isActive, account, provider } = useWeb3React();
  const { chainId } = useChainId();
  const location = useLocation();
  const history = useHistory();
  const isDevelopment = useIsDevelopment();
  const tryActivateConnector = useTryActivateConnector();
  const tryDeactivateConnector = useTryDeactivateConnector();

  useEventToast();

  const query = useRouteQuery();

  useEffect(() => {
    let referralCode = query.get(REFERRAL_CODE_QUERY_PARAM);
    if (!referralCode || referralCode.length === 0) {
      const params = new URLSearchParams(window.location.search);
      referralCode = params.get(REFERRAL_CODE_QUERY_PARAM);
    }

    if (referralCode && referralCode.length <= 20) {
      const encodedReferralCode = encodeReferralCode(referralCode);
      if (encodedReferralCode !== ethers.constants.HashZero) {
        localStorage.setItem(REFERRAL_CODE_KEY, encodedReferralCode);
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has(REFERRAL_CODE_QUERY_PARAM)) {
          queryParams.delete(REFERRAL_CODE_QUERY_PARAM);
          history.replace({
            search: queryParams.toString(),
          });
        }
      }
    }
  }, [query, history, location]);

  const disconnectAccountAndCloseSettings = () => {
    tryDeactivateConnector(connector);
    setIsSettingsVisible(false);
  };

  const userOnMobileDevice = "navigator" in window && isMobileDevice(window.navigator);

  const activateMetaMask = () => {
    if (!walletsSupport.metamask) {
      helperToast.error(
        <div>
          <Trans>MetaMask not detected.</Trans>
          <br />
          <br />
          {userOnMobileDevice ? (
            <Trans>
              <ExternalLink href="https://metamask.io">Install MetaMask</ExternalLink>, and use Foxify with its built-in
              browser.
            </Trans>
          ) : (
            <Trans>
              <ExternalLink href="https://metamask.io">Install MetaMask</ExternalLink> to start using Foxify.
            </Trans>
          )}
        </div>
      );
      return false;
    }
    setIsWalletModalVisible(false);
    setConnectingModalVisible(true);
    tryActivateConnector(getConnection(ConnectionType.INJECTED).connector);
  };

  const activateCoinBase = () => {
    setIsWalletModalVisible(false);
    setConnectingModalVisible(true);
    tryActivateConnector(getConnection(ConnectionType.COINBASE_WALLET).connector);
  };

  const activateWalletConnect = () => {
    setIsWalletModalVisible(false);
    setConnectingModalVisible(true);
    tryActivateConnector(getConnection(ConnectionType.WALLET_CONNECT).connector);
  };

  const { isWalletModalVisible, setIsWalletModalVisible, connectWallet } = useConnectWalletUi();

  const [connectingModalVisible, setConnectingModalVisible] = useState(false);

  const [hasUserAgreedTerms, setHasUserAgreedTerms] = useAtom(hasUserAgreedTermsAtom);

  const handleTryAgainClick = () => {
    setConnectingModalVisible(false);
    setIsWalletModalVisible(true);
  };

  const [isCancelMultipleOrderProcessing, setIsCancelMultipleOrderProcessing] = useState(false);
  const [cancelOrderIdList, setCancelOrderIdList] = useState([]);

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [savedSlippageAmount, setSavedSlippageAmount] = useLocalStorageSerializeKey(
    [chainId, SLIPPAGE_BPS_KEY],
    DEFAULT_SLIPPAGE_AMOUNT
  );
  const [slippageAmount, setSlippageAmount] = useState("0");
  const [isPnlInLeverage, setIsPnlInLeverage] = useState(false);
  const [shouldDisableValidationForTesting, setShouldDisableValidationForTesting] = useState(false);
  const [showPnlAfterFees, setShowPnlAfterFees] = useState(false);

  const [savedIsPnlInLeverage, setSavedIsPnlInLeverage] = useLocalStorageSerializeKey(
    [chainId, IS_PNL_IN_LEVERAGE_KEY],
    false
  );

  const [savedShowPnlAfterFees, setSavedShowPnlAfterFees] = useLocalStorageSerializeKey(
    [chainId, SHOW_PNL_AFTER_FEES_KEY],
    false
  );

  const [savedShouldDisableValidationForTesting, setSavedShouldDisableValidationForTesting] =
    useLocalStorageSerializeKey([chainId, DISABLE_ORDER_VALIDATION_KEY], false);

  const [savedShouldShowPositionLines, setSavedShouldShowPositionLines] = useLocalStorageSerializeKey(
    [chainId, SHOULD_SHOW_POSITION_LINES_KEY],
    false
  );

  const openSettings = () => {
    const slippage = parseInt(String(savedSlippageAmount));
    setSlippageAmount(String((slippage / BASIS_POINTS_DIVISOR) * 100));
    setIsPnlInLeverage(savedIsPnlInLeverage ?? false);
    setShowPnlAfterFees(savedShowPnlAfterFees ?? true);
    setShouldDisableValidationForTesting(savedShouldDisableValidationForTesting ?? false);
    setIsSettingsVisible(true);
  };

  const saveAndCloseSettings = () => {
    const slippage = parseFloat(String(slippageAmount));
    if (isNaN(slippage)) {
      helperToast.error(t`Invalid slippage value`);
      return;
    }
    if (slippage > 5) {
      helperToast.error(t`Slippage should be less than 5%`);
      return;
    }

    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    if (parseInt(String(basisPoints)) !== parseFloat(String(basisPoints))) {
      helperToast.error(t`Max slippage precision is 0.01%`);
      return;
    }

    setSavedIsPnlInLeverage(isPnlInLeverage);
    setSavedShowPnlAfterFees(showPnlAfterFees);
    setSavedShouldDisableValidationForTesting(shouldDisableValidationForTesting);
    setSavedSlippageAmount(basisPoints);
    setIsSettingsVisible(false);
  };

  const [pendingTxns, setPendingTxns] = usePendingTxns();

  const vaultAddress = useMemo(() => getContractAddress(chainId, "Vault"), [chainId]);
  const positionRouterAddress = useMemo(() => getContractAddress(chainId, "PositionRouter"), [chainId]);

  useEffect(() => {
    if (!account) {
      return;
    }

    if (connectingModalVisible) {
      setConnectingModalVisible(false);
    }

    // setWelcomeModalVisible(!welcomeModalWasClosed);
  }, [account, connectingModalVisible]);

  const [notificationPosition] = useLocalStorageReactive<ToastPosition>(
    NOTIFICATION_LOCALSTORAGE_POSITION_KEY,
    "bottom-right"
  );

  const [tradingLayout, setTradingLayout] = useLocalStorageReactive(SETTINGS_LOCALSTORAGE_TRADING_LAYOUT_KEY, "right", {
    raw: true,
  });

  const { proxyTraderContractAddress, isFundedCreated, traderContractAddress } = useFundedTrader();

  useEffect(() => {
    const wsVaultAbi = Vault.abi;
    const wsProvider = getWsProvider(isActive, chainId);
    if (!wsProvider) {
      return;
    }

    const wsVault = new ethers.Contract(vaultAddress, wsVaultAbi, wsProvider);
    const wsPositionRouter = new ethers.Contract(positionRouterAddress, PositionRouter.abi, wsProvider);

    const callExchangeRef = (method, ...args) => {
      if (!exchangeRef || !exchangeRef.current) {
        return;
      }

      exchangeRef.current[method](...args);
    };

    // handle the subscriptions here instead of within the Exchange component to avoid unsubscribing and re-subscribing
    // each time the Exchange components re-renders, which happens on every data update
    const onUpdatePosition = (...args) => callExchangeRef("onUpdatePosition", ...args);
    const onClosePosition = (...args) => callExchangeRef("onClosePosition", ...args);
    const onIncreasePosition = (...args) => callExchangeRef("onIncreasePosition", ...args);
    const onDecreasePosition = (...args) => callExchangeRef("onDecreasePosition", ...args);
    const onCancelIncreasePosition = (...args) => callExchangeRef("onCancelIncreasePosition", ...args);
    const onCancelDecreasePosition = (...args) => callExchangeRef("onCancelDecreasePosition", ...args);

    wsVault.on("UpdatePosition", onUpdatePosition);
    wsVault.on("ClosePosition", onClosePosition);
    wsVault.on("IncreasePosition", onIncreasePosition);
    wsVault.on("DecreasePosition", onDecreasePosition);
    wsPositionRouter.on("CancelIncreasePosition", onCancelIncreasePosition);
    wsPositionRouter.on("CancelDecreasePosition", onCancelDecreasePosition);

    return function cleanup() {
      wsVault.off("UpdatePosition", onUpdatePosition);
      wsVault.off("ClosePosition", onClosePosition);
      wsVault.off("IncreasePosition", onIncreasePosition);
      wsVault.off("DecreasePosition", onDecreasePosition);
      wsPositionRouter.off("CancelIncreasePosition", onCancelIncreasePosition);
      wsPositionRouter.off("CancelDecreasePosition", onCancelDecreasePosition);
    };
  }, [isActive, chainId, vaultAddress, positionRouterAddress]);

  const onMultipleCancelClick = useCallback(
    async function () {
      setIsCancelMultipleOrderProcessing(true);
      try {
        const tx = await cancelMultipleOrders(chainId, provider, cancelOrderIdList, proxyTraderContractAddress, {
          successMsg: t`Orders cancelled.`,
          failMsg: t`Cancel failed.`,
          sentMsg: t`Cancel submitted.`,
          pendingTxns,
          setPendingTxns,
        });
        const receipt = await tx.wait();
        if (receipt.status === 1) {
          setCancelOrderIdList([]);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsCancelMultipleOrderProcessing(false);
      }
    },
    [
      chainId,
      provider,
      pendingTxns,
      setPendingTxns,
      setCancelOrderIdList,
      cancelOrderIdList,
      setIsCancelMultipleOrderProcessing,
      proxyTraderContractAddress,
    ]
  );

  const isMobile = useMedia("(max-width: 1100px)");

  return (
    <>
      <div className="App relative">
        <DynamicBlurredEffect />
        <div className="hidden md:flex">
          <MarketInformation />
        </div>

        <div className="App-content">
          {
            <AppHeader
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
              openSettings={openSettings}
              savedSlippageAmount={savedSlippageAmount!}
              setSavedSlippageAmount={setSavedSlippageAmount}
              savedShouldShowPositionLines={savedShouldShowPositionLines!}
              setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
              tradingLayout={tradingLayout!}
              setTradingLayout={setTradingLayout}
            ></AppHeader>
          }
          {!isDevelopment && (
            <Switch>
              <Route exact path="/">
                <Redirect to="/trade" />
              </Route>

              <Route path="/funded">
                <Funded
                  setPendingTxns={setPendingTxns}
                  pendingTxns={pendingTxns}
                  isFundedCreated={isFundedCreated}
                  traderContractAddress={traderContractAddress}
                />
              </Route>

              <Route path="/kitsune">
                <Kitsune
                />
              </Route>

              {/* <Route exact path="/portfolio">
                <Portfolio
                  savedIsPnlInLeverage={savedIsPnlInLeverage}
                  savedShowPnlAfterFees={savedShowPnlAfterFees}
                  savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  connectWallet={connectWallet}
                  pendingTxns={pendingTxns}
                  setPendingTxns={setPendingTxns}
                  isCancelMultipleOrderProcessing={isCancelMultipleOrderProcessing}
                  cancelOrderIdList={cancelOrderIdList}
                  setCancelOrderIdList={setCancelOrderIdList}
                  onMultipleCancelClick={onMultipleCancelClick}
                />
              </Route> */}

              <Route exact path="/trade">
                <Exchange
                  ref={exchangeRef}
                  savedShowPnlAfterFees={savedShowPnlAfterFees}
                  savedIsPnlInLeverage={savedIsPnlInLeverage}
                  setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                  savedSlippageAmount={savedSlippageAmount}
                  setPendingTxns={setPendingTxns}
                  pendingTxns={pendingTxns}
                  savedShouldShowPositionLines={savedShouldShowPositionLines}
                  setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                  connectWallet={connectWallet}
                  savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  tradingLayout={tradingLayout}
                  isCancelMultipleOrderProcessing={isCancelMultipleOrderProcessing}
                  cancelOrderIdList={cancelOrderIdList}
                  setCancelOrderIdList={setCancelOrderIdList}
                  onMultipleCancelClick={onMultipleCancelClick}
                />
              </Route>

              {process.env.REACT_APP_SHOW_EARN_PAGE === "true" && (
                <Route exact path="/earn">
                  <Stake setPendingTxns={setPendingTxns} connectWallet={connectWallet} />
                </Route>
              )}

              {process.env.REACT_APP_SHOW_PLP_STATS === "true" && (
                <Route exact path="/buy">
                  <Buy
                    savedSlippageAmount={savedSlippageAmount}
                    setPendingTxns={setPendingTxns}
                    connectWallet={connectWallet}
                    savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  />
                </Route>
              )}

              <Route exact path="/referral">
                <Referrals pendingTxns={pendingTxns} connectWallet={connectWallet} setPendingTxns={setPendingTxns} />
              </Route>

              <Route exact path="/referral/:account">
                <Referrals pendingTxns={pendingTxns} connectWallet={connectWallet} setPendingTxns={setPendingTxns} />
              </Route>

              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          )}

          {isDevelopment && (
            <Switch>
              <Route exact path="/">
                <Redirect to="/trade" />
              </Route>

              <Route path="/funded">
                <Funded
                  setPendingTxns={setPendingTxns}
                  pendingTxns={pendingTxns}
                  isFundedCreated={isFundedCreated}
                  traderContractAddress={traderContractAddress}
                />
              </Route>

              <Route path="/kitsune">
                <Kitsune
                />
              </Route>

              <Route exact path="/portfolio">
                <Portfolio
                  savedIsPnlInLeverage={savedIsPnlInLeverage}
                  savedShowPnlAfterFees={savedShowPnlAfterFees}
                  savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  connectWallet={connectWallet}
                  pendingTxns={pendingTxns}
                  setPendingTxns={setPendingTxns}
                  isCancelMultipleOrderProcessing={isCancelMultipleOrderProcessing}
                  cancelOrderIdList={cancelOrderIdList}
                  setCancelOrderIdList={setCancelOrderIdList}
                  onMultipleCancelClick={onMultipleCancelClick}
                />
              </Route>

              <Route exact path="/trade">
                <Exchange
                  ref={exchangeRef}
                  savedShowPnlAfterFees={savedShowPnlAfterFees}
                  savedIsPnlInLeverage={savedIsPnlInLeverage}
                  setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                  savedSlippageAmount={savedSlippageAmount}
                  setPendingTxns={setPendingTxns}
                  pendingTxns={pendingTxns}
                  savedShouldShowPositionLines={savedShouldShowPositionLines}
                  setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                  connectWallet={connectWallet}
                  savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  tradingLayout={tradingLayout}
                  isCancelMultipleOrderProcessing={isCancelMultipleOrderProcessing}
                  cancelOrderIdList={cancelOrderIdList}
                  setCancelOrderIdList={setCancelOrderIdList}
                  onMultipleCancelClick={onMultipleCancelClick}
                />
              </Route>

              {process.env.REACT_APP_SHOW_EARN_PAGE === "true" && (
                <Route exact path="/earn">
                  <Stake setPendingTxns={setPendingTxns} connectWallet={connectWallet} />
                </Route>
              )}

              {process.env.REACT_APP_SHOW_PLP_STATS === "true" && (
                <Route exact path="/buy">
                  <Buy
                    savedSlippageAmount={savedSlippageAmount}
                    setPendingTxns={setPendingTxns}
                    connectWallet={connectWallet}
                    savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  />
                </Route>
              )}

              <Route exact path="/referral">
                <Referrals pendingTxns={pendingTxns} connectWallet={connectWallet} setPendingTxns={setPendingTxns} />
              </Route>

              <Route exact path="/referral/:account">
                <Referrals pendingTxns={pendingTxns} connectWallet={connectWallet} setPendingTxns={setPendingTxns} />
              </Route>

              <Route exact path="/jobs">
                <Jobs />
              </Route>

              <Route exact path="/ecosystem">
                <Ecosystem />
              </Route>

              {/* <Route exact path="/nft_wallet">
                <NftWallet />
              </Route> */}

              <Route exact path="/actions">
                <Actions savedIsPnlInLeverage={savedIsPnlInLeverage} savedShowPnlAfterFees={savedShowPnlAfterFees} />
              </Route>

              <Route exact path="/actions/:account">
                <Actions savedIsPnlInLeverage={savedIsPnlInLeverage} savedShowPnlAfterFees={savedShowPnlAfterFees} />
              </Route>

              <Route exact path="/orders_overview">
                <OrdersOverview />
              </Route>

              <Route exact path="/positions_overview">
                <PositionsOverview />
              </Route>

              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          )}
        </div>
      </div>
      <Portal>
        <ToastContainer
          limit={0}
          transition={Zoom}
          position={notificationPosition}
          autoClose={7000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          draggable={false}
          pauseOnHover
        />
      </Portal>
      <Portal>
        <EventToastContainer />
      </Portal>
      <Modal
        className="Connecting-wallet-modal"
        isVisible={connectingModalVisible}
        setIsVisible={setConnectingModalVisible}
        label={t`Connecting...`}
      >
        <div className="Connecting-wallet-modal">
          <div className="Connecting-wallet-loader">
            <SpinningLoader color="var(--pallete-inactive)" />
          </div>
          <div className="Connecting-wallet-btn" onClick={handleTryAgainClick}>
            <Trans>Try Again</Trans>
          </div>
        </div>
      </Modal>
      <Modal
        className="Welcome-wallet-modal"
        isVisible={!hasUserAgreedTerms}
        setIsVisible={() => {}}
        label={t`Acknowledge Terms`}
        disAllowClose={true}
      >
        <div className="Welcome-wallet-text mb-[1.5rem]">
          <Trans>
            By continuing, you agree to the{" "}
            <a href="https://docs.foxify.trade/" target="_blank" rel="noreferrer" className="text-[1.4rem] text-main">
              Foxify Terms and Conditions{" "}
            </a>
            and acknowledge that you have read and understood the{" "}
            <a href="https://docs.foxify.trade/" target="_blank" rel="noreferrer" className="text-[1.4rem] text-main">
              Foxify Disclaimer
            </a>{" "}
            and the following:
          </Trans>
        </div>

        <div className="flex flex-col gap-[1.5rem]">
          <div className="text-center">
            <span className="text-[1.4rem] text-inactive">
              <Trans>
                <span className="text-white font-medium">Jurisdictional Restrictions:</span> Some services on FOXIFY may
                not be available in certain jurisdictions due to regulatory constraints. It is your responsibility to
                understand and comply with any laws or regulations relevant to you in your country of residence
                regarding the use of FOXIFY.
              </Trans>
            </span>
          </div>
          <div className="text-center">
            <span className="text-[1.4rem] text-inactive">
              <Trans>
                <span className="text-white font-medium">Compliance:</span> By using FOXIFY, you confirm you are not in
                a restricted jurisdiction.
              </Trans>
            </span>
          </div>
          <div className="text-center">
            <span className="text-[1.4rem] text-inactive">
              <Trans>
                <span className="text-white font-medium">Data:</span> Confirm you are happy with the use of cookies and
                online data collection.
              </Trans>
            </span>
          </div>
        </div>

        <Button
          variant="primary-action"
          className="w-100 my-[1.5rem] md:mb-0"
          onClick={() => {
            setHasUserAgreedTerms(true);
          }}
          size="lg"
        >
          <Trans>Agree and Continue</Trans>
        </Button>
      </Modal>

      <Modal
        className="Connecting-wallet-modal"
        isVisible={isWalletModalVisible}
        setIsVisible={setIsWalletModalVisible}
        label={t`Select a Wallet`}
      >
        <div className="Wallets-description">
          By connecting your wallet, you agree to our
          <br />
          Terms of Service and our Privacy Policy.
        </div>
        <div className="Wallet-btns">
          <button className="Wallet-btn MetaMask-btn" onClick={activateMetaMask}>
            <div className="Wallet-btn-start">
              <img src={metamaskImg} alt="MetaMask" />
              <div className="Wallet-btn-text">
                <span className="Wallet-btn-label">MetaMask</span>
                <div className="Wallet-btn-description">
                  <Trans>Connect to your Metamask wallet</Trans>
                </div>
              </div>
            </div>
            <AiOutlineArrowRight className="Wallet-btn-end-icon" fontSize={15} />
          </button>

          <button className="Wallet-btn CoinbaseWallet-btn" onClick={activateCoinBase}>
            <div className="Wallet-btn-start">
              <img src={coinbaseImg} alt="Coinbase Wallet" />
              <div className="Wallet-btn-text">
                <span className="Wallet-btn-label">Coinbase Wallet</span>
                <div className="Wallet-btn-description">
                  <Trans>Connect to your Coinbase wallet</Trans>
                </div>
              </div>
            </div>
            <AiOutlineArrowRight className="Wallet-btn-end-icon" fontSize={15} />
          </button>

          <button className="Wallet-btn WalletConnect-btn" onClick={activateWalletConnect}>
            <div className="Wallet-btn-start">
              <img src={walletConnectImg} alt="WalletConnect" />
              <div className="Wallet-btn-text">
                <span className="Wallet-btn-label">WalletConnect</span>
                <div className="Wallet-btn-description">
                  <Trans>Scan with your mobile device to connect</Trans>
                </div>
              </div>
            </div>
            <AiOutlineArrowRight className="Wallet-btn-end-icon" fontSize={15} />
          </button>
        </div>
        <div className="Wallets-description">
          <Trans>To use Foxify, you need to connect a wallet first.</Trans>
          <br />
          <a href="https://docs.foxify.trade/" rel="noreferrer" target="_blank">
            <Trans>Having trouble connecting a wallet?</Trans>
          </a>
        </div>
      </Modal>

      <BridgeModal />

      <Modal
        className="App-settings tailwind"
        isVisible={isSettingsVisible}
        setIsVisible={setIsSettingsVisible}
        label={t`Trading settings`}
        placement={isMobile ? "center" : "right"}
        disableBodyScrollLock={true}
        isSecondModal={true}
      >
        <div className="App-settings-row">
          <div>
            <Trans>Allowed Slippage</Trans>
          </div>
          <div className="App-slippage-tolerance-input-container">
            <input
              type="number"
              className="App-slippage-tolerance-input"
              min="0"
              value={slippageAmount}
              onChange={(e) => setSlippageAmount(e.target.value)}
            />
            <div className="App-slippage-tolerance-input-percent">in %</div>
          </div>
        </div>
        <div className="Exchange-settings-row">
          <Checkbox isChecked={showPnlAfterFees} setIsChecked={setShowPnlAfterFees}>
            <Trans>Display PnL after fees</Trans>
          </Checkbox>
        </div>
        <div className="Exchange-settings-row">
          <Checkbox isChecked={isPnlInLeverage} setIsChecked={setIsPnlInLeverage}>
            <Trans>Include PnL in leverage display</Trans>
          </Checkbox>
        </div>
        <div className="Exchange-settings-row chart-positions-settings">
          <Checkbox isChecked={savedShouldShowPositionLines} setIsChecked={setSavedShouldShowPositionLines}>
            <span>
              <Trans>Chart positions</Trans>
            </span>
          </Checkbox>
        </div>

        {/* {isDevelopment && (
          <div className="Exchange-settings-row">
            <Checkbox isChecked={shouldDisableValidationForTesting} setIsChecked={setShouldDisableValidationForTesting}>
              <Trans>Disable order validations</Trans>
            </Checkbox>
          </div>
        )} */}

        <Button variant="primary-action" size="lg" className="w-100 mt-[1.5rem]" onClick={saveAndCloseSettings}>
          <Trans>Save</Trans>
        </Button>
      </Modal>
    </>
  );
}

function App() {
  useScrollToTop();
  useEffect(() => {
    const defaultLanguage = localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale;
    dynamicActivate(defaultLanguage);
  }, []);

  return (
    <SWRConfig value={{ refreshInterval: 5000 }}>
      <Web3ReactProvider connectors={connectors} lookupENS={false}>
        <SEO>
          <Router>
            <I18nProvider i18n={i18n}>
              <FullApp />
            </I18nProvider>
          </Router>
        </SEO>
      </Web3ReactProvider>
    </SWRConfig>
  );
}

export default App;
